import axios from 'axios'
import StorageKeys from '../utils/storage-keys'
// import axiosConfig from '@/auth/axios'

/**
 * Class that represents the Base Controller that may be used as
 * base to other controllers, once it has all the basic CRUD needed
 * methods.
 */
class Controller {
  constructor() {
    // axiosConfig.setHeaders()
  }

  /**
   * Sends a file to the be saved into the back-end.
   *
   * @param {string} path the file posting URL.
   * @param {Object} payload an object containing the file.
   * @returns the axios response.
   */
  /* eslint-disable-next-line class-methods-use-this */
  async sendFile(path, payload) {
    const res = await axios
      .post(path, payload, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization:
            'Bearer ' + localStorage.getItem(StorageKeys.accessToken),
        },
      })
      .catch((err) => {
        throw new Error('New error message', { cause: err })
      })
    return res
  }

  /**
   * Creates an object into the database.
   *
   * @param {string} path the data posting URL.
   * @param {Object} payload an object containing the data to be created.
   * @returns the axios response.
   */
  /* eslint-disable-next-line class-methods-use-this */
  async create(path, payload) {
    const res = await axios
      .post(path, payload, {
        headers: {
          Authorization:
            'Bearer ' + localStorage.getItem(StorageKeys.accessToken),
        },
      })
      .catch((err) => {
        throw new Error('New error message', { cause: err })
      })
    return res
  }

  /**
   * Reads some objects from the database.
   *
   * @param {string} path the data reading URL.
   * @param {Object} payload an object containing the request headers.
   * @param {Object} payload.headers some headers to be used into the request.
   * @returns the axios response data.
   */
  /* eslint-disable-next-line class-methods-use-this */
  async read(path, payload) {
    const res = await axios
      .get(path, {
        headers: {
          ...payload.headers,
          Authorization:
            'Bearer ' + localStorage.getItem(StorageKeys.accessToken),
        },
      })
      .catch((err) => {
        throw new Error('New error message', { cause: err })
      })
    return res.data
  }

  /**
   * Reads some objects from the database.
   *
   * @param {string} path the data reading URL.
   * @param {import('axios').AxiosRequestConfig} payload an object containing the request config.
   * @returns the axios response data.
   */
  /* eslint-disable-next-line class-methods-use-this */
  async readOne(path, payload) {
    const res = await axios
      .get(path, {
        headers: {
          Authorization:
            'Bearer ' + localStorage.getItem(StorageKeys.accessToken),
        },
      })
      .catch((err) => {
        throw new Error('New error message', { cause: err })
      })
    return res.data
  }

  /**
   * Updates an object from the database.
   *
   * @param {string} path the data putting URL.
   * @param {Object} payload an object containing the data to be updated.
   * @returns the axios response.
   */
  /* eslint-disable-next-line class-methods-use-this */
  async update(path, payload) {
    const res = await axios
      .put(path, payload, {
        headers: {
          Authorization:
            'Bearer ' + localStorage.getItem(StorageKeys.accessToken),
        },
      })
      .catch((err) => {
        throw new Error('New error message', { cause: err })
      })

    return res
  }

  /**
   * Deletes an object from the database.
   *
   * @param {string} path the data deleting URL.
   * @returns the axios response.
   */
  /* eslint-disable-next-line class-methods-use-this */
  async delete(path) {
    return axios
      .delete(path, {
        headers: {
          Authorization:
            'Bearer ' + localStorage.getItem(StorageKeys.accessToken),
        },
      })
      .catch((err) => {
        throw new Error('New error message', { cause: err })
      })
  }
}

export default Controller
