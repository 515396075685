import { BASE_API } from '@/api/v1'
import PropertyType from '@/models/property-type.model'
import BaseController from './base.controller'

const URL = `${BASE_API}/property-types`

class PropertyTypesController extends BaseController {
  async create(object) {
    return super.create(`${URL}`, object)
  }

  async update(object) {
    return super.update(`${URL}/${object.id}`, object)
  }

  async getAll(payload) {
    const res = await super.read(
      `${URL}?searchEn=${payload.query.searchEn}&searchEs=${payload.query.searchEs}&searchSw=${payload.query.searchSw}&page=${payload.query.page}&perPage=${payload.query.perPage}`,
      payload,
    )
    if (!payload.headers['X-Paginate']) {
      return {
        total: res.length,
        data: this.mapToArray(
          new Map(res.map((obj) => [obj.id, new PropertyType(obj)])),
        ),
      }
    } else {
      return {
        total: res.total,
        data: this.mapToArray(
          new Map(res.data.map((obj) => [obj.id, new PropertyType(obj)])),
        ),
      }
    }
  }

  async getById(id) {
    const res = await super.readOne(`${URL}/${id}`)
    const obj = new PropertyType(res)
    return obj
  }

  async deleteById(id) {
    return super.delete(`${URL}/${id}`)
  }

  /* eslint-disable-next-line class-methods-use-this */
  mapToArray(map) {
    return Array.from(map.values())
  }
}

export default PropertyTypesController
