import { NavigationGuard, NavigationGuardNext, Route } from 'vue-router'

import store from '../store'

import UserModel from '../models/user.model'
import StorageKeys from '../utils/storage-keys'
import { rolesAccess } from '../utils/roles-access'

/**
 * The auth navigation guard, which will be used to check whether
 * the used is already logged and its role. According to the result,
 * it will proceed to the path or will be redirected.
 *
 * @param {Route} to the path the app is navigating to.
 * @param {Route} from the path from where the app started the navigation
 * @param {NavigationGuardNext} next a function that determines the path to be navigated.
 * @returns {NavigationGuard} a navigation guard to be used by the router.
 */
export default async function (to, from, next) {
  const token = localStorage.getItem(StorageKeys.accessToken)
  const userId = localStorage.getItem(StorageKeys.userId)

  if (token) {
    store.dispatch('auth/setToken', token)
    store.dispatch('auth/setUserId', +userId)
  }

  const isAuthenticated = store.getters['auth/isAuthenticated']

  if (!isAuthenticated) {
    if (to.path !== '/login') {
      return next('/login')
    }

    return next()
  }

  if (to.path === '/login') {
    return next('/')
  }

  if (!store.state.auth.user) {
    await store.dispatch('auth/getMe')
  }

  /**
   * @type {UserModel}
   */
  const user = store.state.auth.user

  if (!user) {
    next('/login')
  }

  /**
   * @type {string[]}
   */
  const roleAccess = rolesAccess[to.name]

  if (roleAccess.includes('all') || roleAccess.includes(user.role)) {
    return next()
  }

  next('/')
}
