/**
 * Keeps all routes names and their roles.
 *
 * @example
 * ```
 * rolesAccess['home'] => ['all']
 * rolesAccess['property-types'] => ['superadmin']
 * rolesAccess['demands-list'] => ['superadmin', 'salesperson']
 * ```
 */
export const rolesAccess = {
  'home': ['all'],
  'property-list': ['all'],
  'property-edit': ['superadmin', 'salesperson'],
  'property-actions': ['superadmin', 'salesperson'],
  'property-types': ['superadmin', 'salesperson'],
  'offices-list': ['superadmin', 'salesperson'],
  'office-edit': ['superadmin', 'salesperson'],
  'users-list': ['superadmin', 'salesperson'],
  'user-edit': ['superadmin'],
  'user-set-password': ['all'],
  'demands-list': ['superadmin', 'salesperson'],
  'demand-edit': ['superadmin', 'salesperson'],
  'zones-list': ['superadmin', 'salesperson'],
  'login': ['all'],
  'error-404': ['all'],
}
