import Config from './config.model'

export default class Applicant {
  constructor({
    id,
    name,
    lastName,
    address,
    province,
    city,
    dni,
    telephone,
    cellphone,
    email,
    zipCode,
  } = {}) {
    this.id = id
    this.name = name
    this.lastName = lastName
    this.address = address ?? ''
    this.province =
      province != null ? Config.parseFieldToVisualText(province) : province
    this.city = city != null ? Config.parseFieldToVisualText(city) : city
    this.dni = dni ?? ''
    this.telephone = telephone ?? ''
    this.cellphone = cellphone ?? ''
    this.email = email
    this.zipCode = zipCode ?? ''
  }

  parseFieldsToValidEnums() {
    this.city = Config.parseFieldToValidEnum(this.city)
    this.province = Config.parseFieldToValidEnum(this.province)
  }
}
