import Vue from 'vue'
import VueRouter from 'vue-router'

import authGuard from './auth.guard'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    {
      path: '/',
      name: 'home',
      component: () => import('@/views/Home.vue'),
      meta: {
        pageTitle: 'home',
        breadcrumb: [
          {
            text: 'home',
            active: true,
          },
        ],
      },
    },
    {
      path: '/properties',
      name: 'property-list',
      component: () => import('@/views/property/PropertyList.vue'),
      meta: {
        pageTitle: 'properties',
        breadcrumb: [
          {
            text: 'properties',
            active: true,
          },
        ],
      },
    },
    {
      path: '/properties/edit/:id',
      name: 'property-edit',
      component: () => import('@/views/property/PropertyEdit.vue'),
      meta: {
        pageTitle: 'properties',
        breadcrumb: [
          {
            text: 'properties',
            active: false,
            to: '/properties',
          },
          {
            text: 'edit',
            active: true,
          },
        ],
      },
    },
    {
      path: '/property-actions',
      name: 'property-actions',
      component: () =>
        import('@/views/propertyActions/PropertyActionsList.vue'),
      meta: {
        pageTitle: 'propertyActions',
        breadcrumb: [
          {
            text: 'propertyActions',
            active: true,
          },
        ],
      },
    },
    {
      path: '/property-types',
      name: 'property-types',
      component: () => import('@/views/propertyTypes/PropertyTypesList.vue'),
      meta: {
        pageTitle: 'propertyTypes',
        breadcrumb: [
          {
            text: 'propertyTypes',
            active: true,
          },
        ],
      },
    },
    {
      path: '/offices',
      name: 'offices-list',
      component: () => import('@/views/office/OfficeList.vue'),
      meta: {
        pageTitle: 'offices',
        breadcrumb: [
          {
            text: 'offices',
            active: true,
          },
        ],
      },
    },
    {
      path: '/offices/edit/:id',
      name: 'office-edit',
      component: () => import('@/views/office/OfficeEdit.vue'),
      meta: {
        pageTitle: 'offices',
        breadcrumb: [
          {
            text: 'offices',
            active: false,
            to: '/offices',
          },
          {
            text: 'edit',
            active: true,
          },
        ],
      },
    },
    {
      path: '/users',
      name: 'users-list',
      component: () => import('@/views/user/UserList.vue'),
      meta: {
        pageTitle: 'users',
        breadcrumb: [
          {
            text: 'users',
            active: true,
          },
        ],
      },
    },
    {
      path: '/users/edit/:id',
      name: 'user-edit',
      component: () => import('@/views/user/UserEdit.vue'),
      meta: {
        pageTitle: 'users',
        breadcrumb: [
          {
            text: 'users',
            active: false,
            to: '/users',
          },
          {
            text: 'edit',
            active: true,
          },
        ],
      },
    },
    {
      path: '/users/set-password',
      name: 'user-set-password',
      component: () => import('@/views/user/set-password/UserSetPassword.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/demands',
      name: 'demands-list',
      component: () => import('@/views/demand/DemandList.vue'),
      meta: {
        pageTitle: 'demands',
        breadcrumb: [
          {
            text: 'demands',
            active: true,
          },
        ],
      },
    },
    {
      path: '/zones',
      name: 'zones-list',
      component: () => import('@/views/zone/ZoneList.vue'),
      meta: {
        pageTitle: 'zones',
        breadcrumb: [
          {
            text: 'zones',
            active: true,
          },
        ],
      },
    },
    {
      path: '/demands/edit/:id',
      name: 'demand-edit',
      component: () => import('@/views/demand/DemandEdit.vue'),
      meta: {
        pageTitle: 'demands',
        breadcrumb: [
          {
            text: 'demands',
            active: false,
            to: '/demands',
          },
          {
            text: 'edit',
            active: true,
          },
        ],
      },
    },
    {
      path: '/login',
      name: 'login',
      component: () => import('@/views/auth/Login.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/error-404',
      name: 'error-404',
      component: () => import('@/views/error/Error404.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '*',
      redirect: 'error-404',
    },
  ],
})

router.beforeEach(authGuard)

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

export default router
