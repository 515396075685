import { BASE_API } from '@/api/v1'
import Config from '@/models/config.model'
import Office from '@/models/office.model'
import BaseController from './base.controller'

const URL = `${BASE_API}/offices`

class OfficeController extends BaseController {

    async create(object) {
        object.parseFieldsToValidEnums()
        return super.create(`${URL}`, object)
    }

    async update(object) {
        object.parseFieldsToValidEnums()
        return super.update(`${URL}/${object.id}`, object)
    }

    async getAll(payload) {
        const res = await super.read(`${URL}?search=${payload.query.search}&page=${payload.query.page}&perPage=${payload.query.perPage}&isActive=${payload.query.isActive || ''}`, payload)
        if (!payload.headers['X-Paginate']) {
            return {
                total: res.length,
                data: this.mapToArray(new Map(res.map(obj => [obj.id, new Office(obj)])))
            }
        } else {
            return {
                total: res.total,
                data: this.mapToArray(new Map(res.data.map(obj => [obj.id, new Office(obj)])))
            }
        }
    }

    async getById(id) {
        const res = await super.readOne(`${URL}/${id}`)
        const obj = new Office(res)
        obj.isActive = obj.isActive == 1 ? true : false
        return obj
    }

    async deleteById(id) {
        return super.delete(`${URL}/${id}`)
    }

    /* eslint-disable-next-line class-methods-use-this */
    mapToArray(map) {
        return Array.from(map.values())
    }
}
export default OfficeController
