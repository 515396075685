/**
 * Class responsible for keeping property type properties
 */
export default class PropertyTypeModel {
    constructor({ id, titleEn, titleEs, titleSw } = {}) {
        this.id = id
        this.titleEn = titleEn
        this.titleEs = titleEs
        this.titleSw = titleSw
    }
}
