/**
 * @typedef {'superadmin'|'salesperson'|'applicant'|'propertyowner'|'user'} Role
 */

export default class UserModel {
  constructor({
    id,
    name,
    lastName,
    email,
    role,
    dni,
    isActive,
    email_verified_at,
    phone,
    prefferedLanguage,
  } = {}) {
    this.id = id
    this.name = name
    this.lastName = lastName
    this.email = email
    this.dni = dni
    this.isActive = isActive
    this.email_verified_at = email_verified_at
    this.phone = phone
    this.prefferedLanguage = prefferedLanguage

    /**
     * @type {Role}
     */
    this.role = role
  }
}
