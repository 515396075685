import OfficeController from '@/controllers/office.controller'
import PropertyActionsController from '@/controllers/property-actions.controller'
import PropertyTypesController from '@/controllers/property-types.controller'
import UserController from '@/controllers/user.controller'
import ImagesController from './images.controller'
import PropertyController from './property.controller'
import PropertyImagesController from './property-images.controller'
import SystemController from './system.controller'
import ZoneController from './zone.controller'
import DemandController from './demand.controller'

const demandC = new DemandController()
const officeC = new OfficeController()
const propActC = new PropertyActionsController()
const propTypeC = new PropertyTypesController()
const userC = new UserController()
const propC = new PropertyController()
const propImgC = new PropertyImagesController()
const imagesC = new ImagesController()
const systemC = new SystemController()
const zoneC = new ZoneController()

export function useModule(module) {
  return module === 'office'
    ? officeC
    : module === 'property-actions'
    ? propActC
    : module === 'property-types'
    ? propTypeC
    : module === 'users'
    ? userC
    : module === 'property'
    ? propC
    : module === 'demands'
    ? demandC
    : module === 'property-images'
    ? propImgC
    : module === 'images'
    ? imagesC
    : module === 'system'
    ? systemC
    : module === 'zones'
    ? zoneC
    : null
}
