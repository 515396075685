import Applicant from './applicant.model'
import Config from './config.model'

export default class DemandModel {
  constructor({
    id,
    salesPersonId,
    propertyActionId,
    minPrice,
    minBedroom,
    minBathroom,
    minRestroom,
    minSurface,
    minYearOld,
    minPlot,
    maxPrice,
    maxBedroom,
    maxBathroom,
    maxRestroom,
    maxSurface,
    maxYearOld,
    maxPlot,
    isFurnished,
    hasGarage,
    hasAirConditioner,
    hasGarden,
    // kitchenType,
    // equippedKitchen,
    hasTerrace,
    hasElevator,
    hasPool,
    hasStorageRoom,
    observations,
    salesPerson,
    propertyAction,
    propertyTypes,
    zones,
    applicant,
    matches,
    isActive,
    createdAt,
    updatedAt,
  } = {}) {
    this.id = id
    this.salesPersonId = salesPersonId
    this.propertyActionId = propertyActionId
    this.applicant = new Applicant(applicant)
    this.minPrice = minPrice ?? 0
    this.minBedroom = minBedroom ?? 0
    this.minBathroom = minBathroom ?? 0
    this.minRestroom = minRestroom ?? 0
    this.minSurface = minSurface ?? 0
    this.minYearOld = minYearOld ?? 0
    this.minPlot = minPlot ?? 0
    this.maxPrice = maxPrice ?? 0
    this.maxBedroom = maxBedroom ?? 0
    this.maxBathroom = maxBathroom ?? 0
    this.maxRestroom = maxRestroom ?? 0
    this.maxSurface = maxSurface ?? 0
    this.maxYearOld = maxYearOld ?? 0
    this.maxPlot = maxPlot ?? 0
    this.isFurnished = !!isFurnished
    this.hasGarage = !!hasGarage
    this.hasAirConditioner = !!hasAirConditioner
    this.hasGarden = !!hasGarden
    // this.kitchenType = Config.parseFieldToVisualText(kitchenType)
    // this.equippedKitchen = Config.parseFieldToVisualText(equippedKitchen)
    this.hasTerrace = !!hasTerrace
    this.hasElevator = !!hasElevator
    this.hasPool = !!hasPool
    this.hasStorageRoom = !!hasStorageRoom
    this.observations = observations
    this.salesPerson = salesPerson ?? null
    this.propertyAction = propertyAction ?? null
    this.propertyTypes = propertyTypes ?? []
    this.zones = zones ?? []
    this.matches = matches
    this.isActive = !!isActive
    this.createdAt = createdAt ?? null
    this.updatedAt = updatedAt ?? null
  }

  parseFieldsToValidEnums() {
    // if (typeof this.kitchenType === 'object') {
    //   this.kitchenType = Config.parseFieldToValidEnum(this.kitchenType.value)
    // } else {
    //   this.kitchenType = Config.parseFieldToValidEnum(this.kitchenType)
    // }

    // if (typeof this.equippedKitchen === 'object') {
    //   this.equippedKitchen = Config.parseFieldToValidEnum(this.equippedKitchen.value)
    // } else {
    //   this.equippedKitchen = Config.parseFieldToValidEnum(this.equippedKitchen)
    // }

    this.city = Config.parseFieldToValidEnum(this.city)
    this.province = Config.parseFieldToValidEnum(this.province)
  }
}
