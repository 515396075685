import { BASE_API } from '@/api/v1'
import Property from '@/models/property.model'
import BaseController from './base.controller'
import axios from 'axios'
import StorageKeys from '../utils/storage-keys'

const URL = `${BASE_API}/properties`
const URL_TERRACES = `${BASE_API}/property-terraces`

class PropertyController extends BaseController {
  async create(object) {
    object.parseFieldsToValidEnums()
    object.parseToIdFromObjectKeyLabel()
    //object.owner.parseFieldsToValidEnums()
    return super.create(`${URL}`, object)
  }

  async update(object) {
    object.parseFieldsToValidEnums()
    object.parseToIdFromObjectKeyLabel()
    //object.owner.parseFieldsToValidEnums()
    return super.update(`${URL}/${object.id}`, object)
  }

  async getAll(payload) {
    const res = await super.read(
      `${URL}?owner=${payload.query.owner}&dni=${payload.query.dni}&email=${payload.query.email}&telephone=${payload.query.telephone}&cellphone=${payload.query.cellphone}&available=${payload.query.available}&reserved=${payload.query.reserved}&sold=${payload.query.sold}&favorite=${payload.query.favorite}&ref=${payload.query.ref}&zones=${payload.query.zones}&bedrooms=${payload.query.bedrooms}&propertyTypes=${payload.query.propertyTypes}&propertyActions=${payload.query.propertyActions}&minPrice=${payload.query.minPrice}&maxPrice=${payload.query.maxPrice}&page=${payload.query.page}&perPage=${payload.query.perPage}`,
      payload,
    )
    const Properties = this.mapToArray(
      new Map(res.data.map((obj) => [obj.id, new Property(obj)])),
    )
    return {
      total: res.total,
      data: Properties,
    }
  }

  async getById(id) {
    const res = await super.readOne(`${URL}/${id}`)
    const obj = new Property(res)
    return obj
  }

  async deleteById(id) {
    return super.delete(`${URL}/${id}`)
  }

  async generatePdf(id) {
    const res = await axios
      .get(`${URL}/generate-pdf/${id}`, {
        headers: {

          Authorization:
            'Bearer ' + localStorage.getItem(StorageKeys.accessToken),
        }, responseType: 'blob',
      })
      .catch((err) => {
        throw new Error('New error message', { cause: err })
      })
    return res.data
  }

  async reject(object) {
    const res = await axios
      .get(`${URL}/${object.propertyId}/reject?demandId=${object.demandId}`, {
        headers: {
          Authorization:
            'Bearer ' + localStorage.getItem(StorageKeys.accessToken),
        },
      })
      .catch((err) => {
        throw new Error('New error message', { cause: err })
      })
    return res.data
  }

  async addPropertyTerraces(id, terraces) {
    await axios
      .post(`${URL_TERRACES}`, {
        propertyId: id,
        terraces: terraces
      }, {
        headers: {
          Authorization:
            'Bearer ' + localStorage.getItem(StorageKeys.accessToken),
        },
      })
      .catch((err) => {
        throw new Error('New error message', { cause: err })
      })
    return true
  }

  /* eslint-disable-next-line class-methods-use-this */
  mapToArray(map) {
    return Array.from(map.values())
  }
}
export default PropertyController
