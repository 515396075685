import { BASE_API } from '@/api/v1'
import BaseController from './base.controller'

const URL = `${BASE_API}/system`

class SystemController extends BaseController {

    async getAll() {
        return await super.read(`${URL}`, { headers: {} })
    }
}
export default SystemController
