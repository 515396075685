import { BASE_API } from '@/api/v1'
import axios from 'axios'
import StorageKeys from '../utils/storage-keys'

const URL = `${BASE_API}/property-images`

class PropertyImagesController {
    async addImagesToProperty(propertyId, files, order) {
        const path = `${URL}?propertyId=${propertyId}`

        let formData = new FormData()
        files.forEach(file => {
            formData.append('files[]', file)
        })
        formData.append('order', JSON.stringify(order))

        const res = await axios.post(path, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
                Authorization:
                    'Bearer ' + localStorage.getItem(StorageKeys.accessToken)
            },
        }).catch(err => {
            throw new Error('New error message', { cause: err })
        })
        return res
    }

    async removeImageFromProperty(propertyId, imageId) {
        const path = `${URL}?propertyId=${propertyId}&imageId=${imageId}`

        const res = await axios.delete(path, {
            headers: {
                Authorization:
                    'Bearer ' + localStorage.getItem(StorageKeys.accessToken)
            },
        }).catch(err => {
            throw new Error('New error message', { cause: err })
        })
        return res
    }
}

export default PropertyImagesController