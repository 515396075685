import Config from './config.model'

export default class OfficeModel {
  constructor({
    id,
    name,
    phone,
    email,
    isActive,
    addressLine1,
    addressLine2,
    province,
    city,
    zip,
    fax,
  } = {}) {
    this.id = id
    this.name = name
    this.phone = phone
    this.email = email
    this.isActive = !!isActive
    this.addressLine1 = addressLine1
    this.addressLine2 = addressLine2
    this.province =
      province != null ? Config.parseFieldToVisualText(province) : province
    this.city = city != null ? Config.parseFieldToVisualText(city) : city
    this.zip = zip
    this.fax = fax
  }

  parseFieldsToValidEnums() {
    this.city = Config.parseFieldToValidEnum(this.city)
    this.province = Config.parseFieldToValidEnum(this.province)
  }
}
