/**
 * Class responsible for keeping all app local storage keys.
 */
export default class StorageKeys {
  /**
   * ASA__ACCESS_TOKEN
   */
  static accessToken = 'ASA__ACCESS_TOKEN'

  /**
   * ASA__USER_ID
   */
  static userId = 'ASA__USER_ID'

  /**
   * ASA__LOCALE
   */
  static locale = 'ASA__LOCALE'
}
