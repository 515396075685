import { Store } from 'vuex'

import AuthController from '../../controllers/auth.controller'
import UserModel from '../../models/user.model'

const authController = new AuthController()

export default {
  namespaced: true,
  state: {
    /**
     * Property that defines the user access token.
     *
     * @type {string}
     */
    accessToken: null,

    /**
     * Property that defines the logged user data.
     *
     * @type {UserModel}
     */
    user: null,

    /**
     * Property that defines the user id.
     *
     * @type {number}
     */
    userId: null,
  },
  getters: {
    /**
     * Indicates whether the used is logged.
     *
     * @param {Object} state the auth state.
     * @returns {boolean} whether the access token is available.
     */
    isAuthenticated: (state) => {
      return !!state.accessToken
    },
  },
  mutations: {
    /**
     * Sets the access token into the state.
     *
     * @param {Object} state the auth state
     * @param {string} data the token to be set.
     */
    SET_ACCESS_TOKEN(state, data) {
      state.accessToken = data
    },

    /**
     * Sets the user id into the state.
     *
     * @param {Object} state the auth state
     * @param {number} data the id to be set.
     */
    SET_USER_ID(state, data) {
      state.userId = data
    },

    /**
     * Sets the user data into the state.
     *
     * @param {Object} state the auth state
     * @param {UserModel} data the user data to be set.
     */
    SET_USER(state, data) {
      state.user = data
    },
  },
  actions: {
    /**
     * Sets the access token into the application.
     *
     * @param {Store} store the vuex store.
     * @param {string} payload the token to be set.
     */
    setToken({ commit }, payload) {
      commit('SET_ACCESS_TOKEN', payload)
    },

    /**
     * Sets the user id into the application.
     *
     * @param {Store} store the vuex store.
     * @param {number} payload the id to be set.
     */
    setUserId({ commit }, payload) {
      commit('SET_USER_ID', payload)
    },

    /**
     * Sets the user data into the application.
     *
     * @param {Store} store the vuex store.
     * @param {number} payload the data to be set.
     */
    setUser({ commit }, payload) {
      commit('SET_USER', payload)
    },

    /**
     * Signs an user in using its email and password.
     *
     * @param {AuthModel} payload an object containing the user email and password.
     * @returns an object containing the response data or the error.
     */
    async signIn(_, payload) {
      return await authController.signIn(payload)
    },

    /**
     * Clears the current access token. Used when the user logs
     * out.
     *
     * @param {Store} param0 the vuex store.
     */
    logout({ commit }) {
      commit('SET_ACCESS_TOKEN', null)
      commit('SET_USER', null)
      commit('SET_USER_ID', null)
    },

    /**
     * Gets the user data according to the user's access token.
     *
     * @returns {Promise<{error: Object} | null>} an object containing the user data or the error.
     */
    async getMe({ state, commit }) {
      const response = await authController.getMe(
        state.accessToken,
        state.userId,
      )

      if (!response.error) {
        commit('SET_USER', new UserModel(response.data))
      }

      return response
    },
  },
}
