/**
 * Class responsible for keeping all the user authentication
 * properties.
 */
export default class AuthModel {
  constructor({ email, password } = {}) {
    /**
     * The user sign up email.
     *
     * @example
     * example@co.com
     *
     * @type {string}
     */
    this.email = email

    /**
     * The user sign up password.
     *
     * @example
     * 12345678
     *
     * @type {string}
     */
    this.password = password
  }
}
