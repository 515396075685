import Vue from 'vue'
import { ToastPlugin, ModalPlugin } from 'bootstrap-vue'
import VueCompositionAPI from '@vue/composition-api'

import router from './router'
import store from './store'
import App from './App.vue'

// Global Components
import './global-components'

// 3rd party plugins
import '@/libs/portal-vue'
import '@/libs/toastification'
import i18n from '@/libs/i18n'
import StorageKeys from './utils/storage-keys'

// BSV Plugin Registration
Vue.use(ToastPlugin)
Vue.use(ModalPlugin)

// Composition API
Vue.use(VueCompositionAPI)

// import core styles
require('@core/scss/core.scss')

// import assets styles
require('@/assets/scss/style.scss')

Vue.config.productionTip = false

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
  created() {
    if (!store.getters['auth/isAuthenticated']) {
      return
    }

    store.dispatch('auth/getMe').then((response) => {
      if (response.error) {
        return
      }

      let prefference = response.data.prefferedLanguage

      switch (prefference) {
        case 'spanish':
          prefference = 'es'
          break
        case 'swedish':
          prefference = 'sv'
          break
        default:
          prefference = 'en'
      }

      this.$i18n.locale =
        localStorage.getItem(StorageKeys.locale) ?? prefference
    })
  },
}).$mount('#app')
