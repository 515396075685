import { useModule } from '@/controllers/manager'
import Config from '@/models/config.model'

export default {
    state: {
        config: null,
        loading: false,
        module: 'system'
    },
    mutations: {
        SET_CONFIG(state, payload) {
            state.config = new Config(payload)
        },
        SET_LOADING(state, payload) {
            state.loading = payload
        },
    },
    actions: {
        async getSystemVariables({ commit, state }) {
            commit('SET_LOADING', true)
            const controller = useModule(state.module)
            try {
                const data = await controller.getAll()
                commit('SET_CONFIG', data)
            } catch (e) {
                throw e
            } finally {
                commit('SET_LOADING', false)
            }
        }
    }
}