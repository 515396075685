import Config from './config.model'

export default class Owner {
    constructor({ id, name, address, country, city, dni, telephone, cellphone, email, zipCode } = {}) {
        this.id = id
        this.name = name
        this.address = address
        this.country = country
        this.city = city
        this.dni = dni
        this.telephone = telephone
        this.cellphone = cellphone
        this.email = email
        this.zipCode = zipCode
    }

    // parseFieldsToValidEnums() {
    //     this.city = Config.parseFieldToValidEnum(this.city)
    //     this.province = Config.parseFieldToValidEnum(this.province)
    // }
}