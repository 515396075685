import { BASE_API } from '@/api/v1'
import User from '@/models/user.model'
import BaseController from './base.controller'
import axios from 'axios'
import StorageKeys from '../utils/storage-keys'

const URL = `${BASE_API}/users`

const config = {
  headers: {
    'content-type': 'application/json',
    'Accept': 'application/json',
    'Authorization': 'Bearer ' + localStorage.getItem(StorageKeys.accessToken)
  },
}

class UserController extends BaseController {
  async create(object) {
    return super.create(`${URL}`, object)
  }

  async update(object) {
    return super.update(`${URL}/${object.id}`, object)
  }

  async getAll(payload) {
    const res = await super.read(
      `${URL}?search=${payload.query.search}&page=${
        payload.query.page
      }&perPage=${payload.query.perPage}&role=${payload.query.role || ''}&isActive=${payload.query.isActive || ''}`,
      payload,
    )
    if (!payload.headers['X-Paginate']) {
      return {
        total: res.length,
        data: this.mapToArray(
          new Map(res.map((obj) => [obj.id, new User(obj)])),
        )
      }
    } else {
      return {
        total: res.total,
        data: this.mapToArray(
          new Map(res.data.map((obj) => [obj.id, new User(obj)])),
        )
      }
    }
  }

  async getById(id) {
    const res = await super.readOne(`${URL}/${id}`)
    const obj = new User(res)
    return obj
  }

  async deleteById(id) {
    return super.delete(`${URL}/${id}`)
  }

  /* eslint-disable-next-line class-methods-use-this */
  async setPassword(payload, token) {
    const res = await axios
      .post(`${URL}/set-password?token=${token}`, payload, config)
      .catch((error) => {
        return {
          error,
        }
      })
    return res
  }

  /* eslint-disable-next-line class-methods-use-this */
  async sendVerificationEmail(payload) {
    const res = await axios
      .post(`${URL}/send-verification-email`, payload, config)
      .catch((err) => {
        throw new Error('New error message', { cause: err })
      })
    return res
  }

  /* eslint-disable-next-line class-methods-use-this */
  mapToArray(map) {
    return Array.from(map.values())
  }
}

export default UserController
