import Config from './config.model'

export default class ZoneModel {
  constructor({ id, province, city, name, isActive } = {}) {
    this.id = id
    this.province = province
      ? Config.parseFieldToVisualText(province)
      : province
    this.city = city ? Config.parseFieldToVisualText(city) : city
    this.name = name
    this.isActive = !!isActive
  }

  parseFieldsToValidEnums() {
    this.city = Config.parseFieldToValidEnum(this.city)
    this.province = Config.parseFieldToValidEnum(this.province)
  }
}
