import axios from 'axios'

import { BASE_API } from '../api/v1'
import AuthModel from '../models/auth.model'
import UserModel from '../models/user.model'

/**
 * Class responsible for keep all authentication related
 * properties and methods.
 */
export default class AuthController {
  /**
   * Calls the API auth endpoint sending the user email and
   * password.
   *
   * @param {AuthModel} payload the user sign up data.
   * @returns {Promise<{token: string, user: UserModel} | {error: Object}>}
   */
  async signIn(payload) {
    try {
      const response = await axios.post(BASE_API + '/auth/login', {
        email: payload.email,
        password: payload.password,
      })

      return {
        token: response.data.token,
        user: response.data.user,
      }
    } catch (error) {
      return {
        error,
      }
    }
  }

  /**
   * Gets the signed user data from the database.
   *
   * @param {string} token the user access token.
   * @param {number} id the user id.
   * @returns {Promise<{ data: UserModel } | {error: Object}>}
   */
  async getMe(token, id) {
    try {
      const response = await axios.get(`${BASE_API}/users/${id}`, {
        headers: {
          Authorization: 'Bearer ' + token,
        },
      })

      return {
        data: response.data,
      }
    } catch (error) {
      return {
        error,
      }
    }
  }
}
