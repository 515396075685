import Owner from '@/models/owner.model'
import Config from './config.model'

export default class PropertyModel {
  constructor({
    id,
    ref,
    province,
    city,
    zoneId,
    zipCode,
    addressTrackType,
    addressTrackName,
    propertyTypeId,
    propertyActionId,
    price,
    officeId,
    userId,
    owner,
    isBrandNew,
    isReserved,
    isSold,
    isHighlighted,
    qtyBedrooms,
    qtyBathrooms,
    qtyRestrooms,
    floor,
    streetNumber,
    block,
    totalFloors,
    orientation,
    buildSurface,
    usefulSurface,
    plotMeters,
    yearBuilt,
    conservationMeters,
    isRenovated,
    communityFee,
    ibi,
    garbageTax,
    kitchenType,
    equippedKitchen,
    interiorCarpentry,
    carpentryOutside,
    hotWater,
    typeOfFloor,
    viewType,
    hasTerrace,
    terraceSize,
    isFurnished,
    hasGarage,
    hasCommunityParking,
    isParkingNumbered,
    hasWardrobes,
    hasChimney,
    hasElevator,
    hasUrbanization,
    hasBalcony,
    hasGallery,
    hasStorageRoom,
    hasPool,
    hasAirConditioner,
    hasHeating,
    hasReception,
    hasSportsZone,
    hasPlayground,
    hasGarden,
    hasAlarm,
    hasVideoIntercom,
    energyRating,
    energyPerformance,
    emissionsRating,
    emissions,
    titleWebEn,
    titleWebEs,
    titleWebSw,
    descriptionEn,
    descriptionEs,
    descriptionSw,
    registry,
    propertyRegister,
    terrainNumber,
    propertyAddress,
    comission,
    visitHours,
    expiryDate,
    mortgagesLeft,
    buyExpenses,
    isAvailable,
    wasLived,
    isSigned,
    hasAvailableKeys,
    isExclusive,
    parkingNumbered,
    garageNumber,
    internalObservations,
    isFavorite,
    images,
    rejected,
    propertyTerraces
  } = {}) {
    this.id = id
    this.ref = ref
    this.province = province
      ? Config.parseFieldToVisualText(province)
      : province
    this.city = city ? Config.parseFieldToVisualText(city) : city
    this.zoneId = zoneId
    this.zipCode = zipCode
    this.addressTrackName = addressTrackName
    this.addressTrackType = addressTrackType
      ? Config.parseFieldToVisualText(addressTrackType)
      : addressTrackType
    this.propertyTypeId = propertyTypeId
    this.propertyActionId = propertyActionId
    this.price = price
    this.officeId = officeId
    this.userId = userId
    this.owner = new Owner(owner)
    this.isBrandNew = isBrandNew
    this.isReserved = isReserved
    this.isSold = isSold
    this.isHighlighted = isHighlighted
    this.qtyBedrooms = qtyBedrooms
    this.qtyBathrooms = qtyBathrooms
    this.qtyRestrooms = qtyRestrooms
    this.floor = floor
    this.streetNumber = streetNumber
    this.block = block
    this.orientation = orientation
    this.totalFloors = totalFloors
    this.buildSurface = buildSurface
    this.usefulSurface = usefulSurface
    this.plotMeters = plotMeters
    this.yearBuilt = yearBuilt
    this.conservationMeters = conservationMeters
    this.isRenovated = isRenovated
    this.communityFee = communityFee
    this.ibi = ibi
    this.garbageTax = garbageTax
    this.kitchenType = kitchenType
      ? Config.parseFieldToVisualText(kitchenType)
      : kitchenType
    this.equippedKitchen = equippedKitchen
      ? Config.parseFieldToVisualText(equippedKitchen)
      : equippedKitchen
    this.interiorCarpentry = interiorCarpentry
      ? Config.parseFieldToVisualText(interiorCarpentry)
      : interiorCarpentry
    this.carpentryOutside = carpentryOutside
      ? Config.parseFieldToVisualText(carpentryOutside)
      : carpentryOutside
    this.hotWater = hotWater
      ? Config.parseFieldToVisualText(hotWater)
      : hotWater
    this.typeOfFloor = typeOfFloor
      ? Config.parseFieldToVisualText(typeOfFloor)
      : typeOfFloor
    this.viewType = viewType
      ? Config.parseFieldToVisualText(viewType)
      : viewType
    this.hasTerrace = hasTerrace
    this.terraceSize = terraceSize
    this.isFurnished = isFurnished
    this.hasGarage = hasGarage
    this.hasCommunityParking = hasCommunityParking
    this.isParkingNumbered = isParkingNumbered
    this.hasWardrobes = hasWardrobes
    this.hasChimney = hasChimney
    this.hasElevator = hasElevator
    this.hasUrbanization = hasUrbanization
    this.hasBalcony = hasBalcony
    this.hasGallery = hasGallery
    this.hasStorageRoom = hasStorageRoom
    this.hasPool = hasPool
    this.hasAirConditioner = hasAirConditioner
    this.hasHeating = hasHeating
    this.hasReception = hasReception
    this.hasSportsZone = hasSportsZone
    this.hasPlayground = hasPlayground
    this.hasGarden = hasGarden
    this.hasAlarm = hasAlarm
    this.hasVideoIntercom = hasVideoIntercom
    this.energyRating = energyRating
      ? Config.parseFieldToVisualText(energyRating)
      : energyRating
    this.energyPerformance = energyPerformance
    this.emissionsRating = emissionsRating
      ? Config.parseFieldToVisualText(emissionsRating)
      : emissionsRating
    this.emissions = emissions
    this.titleWebEn = titleWebEn
    this.titleWebEs = titleWebEs
    this.titleWebSw = titleWebSw
    this.descriptionEn = descriptionEn
    this.descriptionEs = descriptionEs
    this.descriptionSw = descriptionSw
    this.registry = registry
    this.propertyRegister = propertyRegister
    this.terrainNumber = terrainNumber
    this.propertyAddress = propertyAddress
    this.comission = comission
    this.visitHours = visitHours
    this.expiryDate = expiryDate
    this.mortgagesLeft = mortgagesLeft
    this.buyExpenses = buyExpenses
    this.isAvailable = isAvailable
    this.wasLived = wasLived
    this.isSigned = isSigned
    this.hasAvailableKeys = hasAvailableKeys
    this.isExclusive = isExclusive
    this.parkingNumbered = parkingNumbered
    this.garageNumber = garageNumber
    this.internalObservations = internalObservations
    this.isFavorite = isFavorite
    this.images = images,
    this.rejected = rejected,
    this.propertyTerraces = propertyTerraces
  }

  parseFieldsToValidEnums() {
    this.city = Config.parseFieldToValidEnum(this.city)
    this.province = Config.parseFieldToValidEnum(this.province)
    this.kitchenType = Config.parseFieldToValidEnum(this.kitchenType)
    this.equippedKitchen = Config.parseFieldToValidEnum(this.equippedKitchen)
    this.interiorCarpentry = Config.parseFieldToValidEnum(this.interiorCarpentry)
    this.carpentryOutside = Config.parseFieldToValidEnum(this.carpentryOutside)
    this.hotWater = Config.parseFieldToValidEnum(this.hotWater)
    this.typeOfFloor = Config.parseFieldToValidEnum(this.typeOfFloor)
    this.viewType = Config.parseFieldToValidEnum(this.viewType)
    this.energyRating = Config.parseFieldToValidEnum(this.energyRating)
    this.emissionsRating = Config.parseFieldToValidEnum(this.emissionsRating)
    this.orientation = Config.parseFieldToValidEnum(this.orientation)
    this.addressTrackType = Config.parseFieldToValidEnum(this.addressTrackType)
  }

  parseToIdFromObjectKeyLabel() {
    this.propertyActionId = Config.parseToIdFromObjectKeyLabel(
      this.propertyActionId,
    )
    this.propertyTypeId = Config.parseToIdFromObjectKeyLabel(
      this.propertyTypeId,
    )
    this.userId = Config.parseToIdFromObjectKeyLabel(this.userId)
    this.officeId = Config.parseToIdFromObjectKeyLabel(this.officeId)
    this.zoneId = Config.parseToIdFromObjectKeyLabel(this.zoneId)
  }
}
