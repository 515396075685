import { BASE_API } from '@/api/v1'
import DemandModel from '@/models/demand.model'
import Demand from '@/models/demand.model'
import Applicant from '../models/applicant.model'
import PropertyModel from '../models/property.model'
import BaseController from './base.controller'

const URL = `${BASE_API}/demands`

/**
 * Class that represents the Demand Controller, which extends from
 * the Base Controller. It may be used to create, update, read and
 * delete demands.
 */
export default class DemandController extends BaseController {
  /**
   * Creates a demand into the database.
   *
   * @param {Demand} demand the demand to be created.
   * @returns {Promise<import('axios').AxiosResponse<Demand>>} the axios creation response.
   */
  async create(demand) {
    demand.parseFieldsToValidEnums()
    demand.applicant.parseFieldsToValidEnums()
    return super.create(`${URL}`, demand)
  }

  /**
   * Updates a demand from the database.
   *
   * @param {Demand} demand an object containing the demand data to be updated.
   * @returns {Promise<import('axios').AxiosResponse<Demand>>} the axios update response.
   */
  async update(demand) {
    demand.parseFieldsToValidEnums()
    demand.applicant.parseFieldsToValidEnums()
    return super.update(`${URL}/${demand.id}`, demand)
  }

  /**
   * Reads all demands from the database.
   *
   * @param {Object} payload an object containing the request pagination data.
   * @returns {Promise<{total: number, data: Demand[]}>} the demands total amount and data.
   */
  async getAll(payload) {
    const res = await super.read(
      `${URL}?id=${payload.query.id}&propertyActions=${payload.query.propertyActions}&propertyTypes=${payload.query.propertyTypes}&page=${payload.query.page}&perPage=${payload.query.perPage}`,
      payload,
    )

    const demands = this.mapToArray(
      new Map(
        res.data.map((demand) => [
          demand.id,
          new Demand({
            ...demand,
            createdAt: demand.createdAt || demand.created_at,
            updatedAt: demand.updatedAt || demand.updated_at,
          }),
        ]),
      ),
    )

    const demandsData = []

    const promises = demands.map(async (demand) => {
      const matchesRes = await super.read(`${URL}/matches/${demand.id}`, {
        headers: {},
      })

      demandsData.push(
        new DemandModel({
          ...demand,
          matches: matchesRes.map((p) => new PropertyModel(p)),
        }),
      )
      return demand
    })

    await Promise.all(promises)
    return {
      total: res.total,
      data: demandsData,
    }
  }

  /**
   * Gets all database applicants.
   *
   * @returns {Applicant[]} a list of applicants.
   */
  async getAllApplicants() {
    const res = await super.read(`${URL}/applicants`, { headers: {} })

    return res.map(
      (applicant) =>
        new Applicant({
          ...applicant,
          createdAt: applicant.createdAt || applicant.created_at,
          updatedAt: applicant.updatedAt || applicant.updated_at,
        }),
    )
  }

  /**
   * Reads a single demand from the database using its id.
   *
   * @param {string} id the demand id.
   * @returns {Promise<Demand>} the corresponding demand data.
   */
  async getById(id) {
    const data = await super.readOne(`${URL}/${id}`)
    const demand = new Demand({
      ...data,
      createdAt: data.createdAt || data.created_at,
      updatedAt: data.updatedAt || data.updated_at,
    })

    demand.isActive = demand.isActive == 1 ? true : false
    return demand
  }

  /**
   * Deletes a demand from the database.
   *
   * @param {string} id the id of the demand to be deleted.
   * @returns the axios response.
   */
  async deleteById(id) {
    return super.delete(`${URL}/${id}`)
  }

  /**
   * Gets an array of demands according to the given object/map.
   *
   * @param {Object} map turns an object into a demands array.
   * @returns {Demand[]} an array of demands.
   */
  /* eslint-disable-next-line class-methods-use-this */
  mapToArray(map) {
    return Array.from(map.values())
  }
}
