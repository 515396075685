export default class Config {
    constructor({
        addressTrackTypeOptions,
        carpentryOutsideOptions,
        emissionsRatingOptions,
        enegyRatingOptions,
        equippedKitchenOptions,
        hotWaterOptions,
        interiorCarpentryOptions,
        kitchenTypeOptions,
        orientationOptions,
        typeOfFloorOptions,
        userPrefferedLanguageOptions,
        userRoleOptions,
        viewTypeOptions,
        cityOptions,
        provinceOptions,
    } = {}) {
        this.addressTrackTypeOptions = this.toArrayOfKeyObject(addressTrackTypeOptions)
        this.carpentryOutsideOptions = this.toArrayOfKeyObject(carpentryOutsideOptions)
        this.emissionsRatingOptions = this.toArrayOfKeyObject(emissionsRatingOptions)
        this.enegyRatingOptions = this.toArrayOfKeyObject(enegyRatingOptions)
        this.equippedKitchenOptions = this.toArrayOfKeyObject(equippedKitchenOptions)
        this.hotWaterOptions = this.toArrayOfKeyObject(hotWaterOptions)
        this.interiorCarpentryOptions = this.toArrayOfKeyObject(interiorCarpentryOptions)
        this.kitchenTypeOptions = this.toArrayOfKeyObject(kitchenTypeOptions)
        this.orientationOptions = this.toArrayOfKeyObject(orientationOptions)
        this.typeOfFloorOptions = this.toArrayOfKeyObject(typeOfFloorOptions)
        this.userPrefferedLanguageOptions = this.toArrayOfKeyObject(userPrefferedLanguageOptions)
        this.userRoleOptions = this.toArrayOfKeyObject(userRoleOptions)
        this.viewTypeOptions = this.toArrayOfKeyObject(viewTypeOptions)
        this.cityOptions = this.toArrayOfKeyObject(cityOptions)
        this.provinceOptions = this.toArrayOfKeyObject(provinceOptions)
    }

    toArrayOfKeyObject(obj) {
        return Object.keys(obj)
            .map(function (key) {
                return obj[key].toUpperCase().replace(/_/g, " ");
            });
    }

    static parseFieldToValidEnum(value) {
        if (value != null) {
            if (typeof value === 'object') {
                return value.value.toLowerCase().replace(/ /g, "_")
            } else {
                return value.toLowerCase().replace(/ /g, "_")
            }
        }
        return value
    }

    static parseFieldToVisualText(value) {
        if (value) {
            if (typeof value === 'object') {
                return value.value.toUpperCase().replace(/_/g, " ");
            } else {
                return value.toUpperCase().replace(/_/g, " ");
            }
        }
    }

    static parseToIdFromObjectKeyLabel(value) {
        return value ? value.key ? value.key : value : value
    }
}