import { BASE_API } from '@/api/v1'
import Image from '@/models/image.model'
import BaseController from './base.controller'

const URL = `${BASE_API}/images`

class ImagesController extends BaseController {
    async getAll(payload) {
        const res = await super.read(`${URL}`, payload)
        const images = this.mapToArray(new Map(res.data.map(obj => [obj.id, new Image(obj)])))

        return {
            data: images
        }
    }

    async deleteById(id) {
        return super.delete(`${URL}/${id}`)
    }
}

export default ImagesController